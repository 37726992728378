import React from 'react'

import SEO from '../components/seo'

function IndexPage() {
    return (
        <div>
            <SEO title="The about page" keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]} />
        </div>
    )
}

export default IndexPage
